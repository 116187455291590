import axios from 'axios';

interface IData {
  url: string;
  uuid: string;
  isDownload: boolean;
}

export const downloadPDF = (data: IData) => {
  return axios.post('/api/downloadPDF', { ...data });
};
