import KeepExploringIcon from 'assets/icons/explore.svg';
import HelpIcon from 'assets/icons/help-black.svg';
import TopInsightsIcon from 'assets/icons/lightbulb.svg';
import UploadIcon from 'assets/icons/upload.svg';
import CompassImage from 'assets/images/iconiq-compass-logo.png';
import { decode } from 'base-64';
import ButtonWithIcon from 'components/ButtonWithIcon';
import DataEntryModal from 'components/DataEntryModal';
import FiltersSection from 'components/FiltersSection';
import Footer from 'components/Footer';
import FullWidthSection from 'components/FullWidthSection';
import GeoChart from 'components/GeoChart';
import GrowthEnterprise from 'components/GrowthEnterprise';
import HalfWidthSection from 'components/HalfWidthSection';
import Header from 'components/Header';
import LinksCard from 'components/LinksCard';
import LinksCardWithImage from 'components/LinksCardWithImage';
import LongerDisclousure from 'components/LongerDisclousure';
import SectionCharts from 'components/SectionCharts';
import LoadingComponent from 'components/SectionCharts/LoadingComponent';
import SubscribeSection from 'components/SubscribeSection';
import CustomTooltip from 'components/Tooltip';
import WarningModal from 'components/WarningModal';
import { FilterOption } from 'interfaces/components/filter';
import { PriorityItem } from 'interfaces/constants';
import { getData } from 'network/getData';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { isMobile, isTablet } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import {
  setCompanyDetails,
  setCompanyEmail,
  setIsEntered,
  setOtherInfo,
  setRequiredInfo
} from 'store/companyInfo/companyInfo.slice';
import { setIsLoading } from 'store/loadingChart/loadingChart.slice';
import {
  mockedBarChartOptions,
  pricingModelData,
  subSectorData
} from 'utils/chart-options/mocked-chart-data';
import {
  arrPriority,
  companyStatus,
  defaultPriority,
  growthMotionPriority,
  modalModes,
  softwareSectorPriority,
  urlParamNames,
  walkthroughSteps
} from 'utils/constants';
import { ChartDataMap } from 'utils/helpers/mapChartsData';
import './App.css';
import Joyride, { ACTIONS, CallBackProps, STATUS } from 'react-joyride';
import CustomWalkthroughTooltip from 'components/CustomWalkthroughTooltip';

function App() {
  const [data, setData] = useState<ChartDataMap[]>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
  const [warningModalOpen, setWarningModalOpen] = useState(!isDesktop);
  const [warningModalConfirmed, setWarningModalConfirmed] = useState(false);
  const [walkthroughActive, setWalkthroughActive] = useState(false);

  useEffect(() => {
    if (!isDesktop && !(isMobile || isTablet)) {
      setWarningModalOpen(true);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (
      localStorage.getItem('dashboard_tutorial_finished') !== 'true' &&
      !warningModalOpen
    ) {
      setWalkthroughActive(true);
    } else {
      setWalkthroughActive(false);
    }
  }, [warningModalOpen]);

  useEffect(() => {
    checkURL();
    loadData();
    if (!localStorage.getItem('uuid')) {
      const uuid = crypto.randomUUID();
      localStorage.setItem('uuid', uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWalkthroughCallback = (data: CallBackProps) => {
    if (data.action === ACTIONS.SKIP) {
      localStorage.setItem('dashboard_tutorial_finished', 'true');
    }
    if (data.status === STATUS.FINISHED) {
      localStorage.setItem('dashboard_tutorial_finished', 'true');
      window.scrollTo(0, 0);
    }
  };

  const onCloseWarningModal = () => {
    document
      .querySelector('meta[name=viewport]')
      ?.setAttribute('content', 'width=1280, initial-scale=0');
    setWarningModalOpen(false);
    setWarningModalConfirmed(true);
  };
  const isLoading = useSelector(
    (state: RootState) => state.loadingChart.isLoading
  );

  const loadData = async () => {
    dispatch(setIsLoading(true));
    setErrorMessage(null);
    try {
      const data = await getData();
      setData(data as ChartDataMap[]);
    } catch (error: any) {
      setErrorMessage(error.message);
      dispatch(setIsLoading(false));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const saveDataToRedux = (urlParams: URLSearchParams) => {
    dispatch(
      setCompanyDetails({
        status: urlParams.get(urlParamNames.COMPANY_STATUS)
          ? urlParams.get(urlParamNames.COMPANY_STATUS) ===
            companyStatus.PRIVATE
            ? companyStatus.PRIVATE
            : companyStatus.PUBLIC
          : null
      })
    );
    dispatch(
      setRequiredInfo({
        currentARR: urlParams.get(urlParamNames.CURRENT_ARR)
          ? Number(urlParams.get(urlParamNames.CURRENT_ARR))
          : null,
        yoyARR: urlParams.get(urlParamNames.YOY_ARR)
          ? Number(urlParams.get(urlParamNames.YOY_ARR))
          : null,
        fcfMargin: urlParams.get(urlParamNames.FCF_MARGIN)
          ? Number(urlParams.get(urlParamNames.FCF_MARGIN))
          : null,
        totalHeadcount: urlParams.get(urlParamNames.TOTAL_HEADCOUNT)
          ? Number(urlParams.get(urlParamNames.TOTAL_HEADCOUNT))
          : null
      })
    );
    dispatch(
      setOtherInfo({
        companyWebsite: urlParams.get(urlParamNames.COMPANY_WEBSITE) || '',
        grossMargin: urlParams.get(urlParamNames.GROSS_MARGIN)
          ? Number(urlParams.get(urlParamNames.GROSS_MARGIN))
          : null,
        netDollarRetention: urlParams.get(urlParamNames.NET_DOLLAR_RETENTION)
          ? Number(urlParams.get(urlParamNames.NET_DOLLAR_RETENTION))
          : null,
        netMagicNumber: urlParams.get(urlParamNames.NET_MAGIC_NUMBER)
          ? Number(urlParams.get(urlParamNames.NET_MAGIC_NUMBER))
          : null,
        burnMultiple: urlParams.get(urlParamNames.BURN_MULTIPLE)
          ? Number(urlParams.get(urlParamNames.BURN_MULTIPLE))
          : null,
        quickRatio: urlParams.get(urlParamNames.QUICK_RATIO)
          ? Number(urlParams.get(urlParamNames.QUICK_RATIO))
          : null,
        rD: urlParams.get(urlParamNames.RD)
          ? Number(urlParams.get(urlParamNames.RD))
          : null,
        gA: urlParams.get(urlParamNames.GA)
          ? Number(urlParams.get(urlParamNames.GA))
          : null,
        sM: urlParams.get(urlParamNames.SM)
          ? Number(urlParams.get(urlParamNames.SM))
          : null,
        rDHead: urlParams.get(urlParamNames.RD_HEAD)
          ? Number(urlParams.get(urlParamNames.RD_HEAD))
          : null,
        gAHead: urlParams.get(urlParamNames.GA_HEAD)
          ? Number(urlParams.get(urlParamNames.GA_HEAD))
          : null,
        sMHead: urlParams.get(urlParamNames.SM_HEAD)
          ? Number(urlParams.get(urlParamNames.SM_HEAD))
          : null
      })
    );
    dispatch(setCompanyEmail(urlParams.get(urlParamNames.EMAIL) || ''));
    dispatch(setIsEntered(true));
  };

  const checkURL = () => {
    const params = new URL(document.URL).searchParams;
    const encodedParams = params.get('params');
    if (encodedParams) {
      let decodedParams = '';
      try {
        decodedParams = decode(encodedParams);
      } catch (_error) {
        console.log('Failed to decode');
      }
      const urlParams = new URLSearchParams(decodedParams);
      if (urlParams.get(urlParamNames.COMPANY_STATUS)) {
        saveDataToRedux(urlParams);
        sessionStorage.setItem('uuid', urlParams.get('uuid') || '');
      }
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [arr, setArr] = useState<FilterOption[]>([]);
  const [softwareSector, setSoftwareSector] = useState<FilterOption[]>([]);
  const [growthMotion, setGrowthMotion] = useState<FilterOption[]>([]);
  const [primaryCustomer, setPrimaryCustomer] = useState<FilterOption[]>([]);

  const [keepExploring, setKeepExploring] = useState<PriorityItem[]>([]);

  const companyInfo = useAppSelector(state => state.companyInfo);

  const [modalMode, setModalMode] = useState(modalModes.ADD);

  const onClose = () => {
    setIsOpen(false);
  };

  const onGrowthEnterpriseUpdate = () => {
    setModalMode(modalModes.EDIT);
    setIsOpen(true);
  };

  const onGrowthEnterpriseReset = () => {
    setModalMode(modalModes.ADD);
    window.history.pushState({}, '', '/');
  };

  const handleApply = (
    arrFilter: FilterOption[],
    softwareSectorFilter: FilterOption[],
    growthMotionFilter: FilterOption[],
    primaryCustomerFilter: FilterOption[]
  ) => {
    setArr(arrFilter);
    setGrowthMotion(growthMotionFilter);
    setSoftwareSector(softwareSectorFilter);
    setPrimaryCustomer(primaryCustomerFilter);
  };

  useEffect(() => {
    const newKeepExploring: PriorityItem[] = [];
    if (softwareSector.length) {
      softwareSector.forEach(sector => {
        if (softwareSectorPriority[sector.label]) {
          newKeepExploring.push(softwareSectorPriority[sector.label][0]);
        }
      });
    }
    if (growthMotion.length) {
      growthMotion.forEach(growth => {
        if (growthMotionPriority[growth.label]) {
          growthMotionPriority[growth.label].forEach(growthPriority => {
            if (newKeepExploring.length < 2) {
              newKeepExploring.push(growthPriority);
            } else {
              newKeepExploring.forEach((newKeepExploringItem, index) => {
                if (
                  growthPriority.globalPriority <=
                    newKeepExploringItem?.globalPriority &&
                  growthPriority.localPriority <
                    newKeepExploringItem.localPriority &&
                  !newKeepExploring.find(
                    item => item.title === growthPriority.title
                  )
                ) {
                  newKeepExploring[index] = growthPriority;
                }
              });
            }
          });
        }
      });
    }
    if (arr.length) {
      arr.forEach(arrChoice => {
        if (arrPriority[arrChoice.label]) {
          arrPriority[arrChoice.label].forEach(arrChoicePriority => {
            if (newKeepExploring.length < 2) {
              newKeepExploring.push(arrChoicePriority);
            } else {
              newKeepExploring.forEach((newKeepExploringItem, index) => {
                if (
                  arrChoicePriority.globalPriority <=
                    newKeepExploringItem?.globalPriority &&
                  arrChoicePriority.localPriority <
                    newKeepExploringItem.localPriority &&
                  !newKeepExploring.find(
                    item => item.title === arrChoicePriority.title
                  )
                ) {
                  newKeepExploring[index] = arrChoicePriority;
                }
              });
            }
          });
        }
      });
    }
    if (newKeepExploring.length === 1) {
      newKeepExploring.push(defaultPriority[0]);
    } else if (newKeepExploring.length === 0) {
      newKeepExploring.push(defaultPriority[0]);
      newKeepExploring.push(defaultPriority[1]);
    }

    setKeepExploring(newKeepExploring);
  }, [arr, softwareSector, growthMotion]);

  const onComparePerformance = () => {
    setIsOpen(true);
  };

  const renderKeepExploring = () => {
    return keepExploring.map((explore, index) => {
      return (
        <LinksCardWithImage
          key={index}
          title={explore.title}
          description={explore.description}
          link={explore.link}
          image={explore.image}
        />
      );
    });
  };

  return (
    <div className='App'>
      <main
        className={`App-header max-w-[1660px] mx-auto ${
          !(isMobile || isTablet) && warningModalConfirmed ? 'w-[1280px]' : ''
        }`}
      >
        <Header />
        <div className='w-full flex flex-col items-start px-20 py-6 gap-8 bg-gray-200'>
          <div className='w-full flex flex-row items-center justify-between gap-5'>
            <img src={CompassImage} alt='' className='w-[300px]' />
            <p className='text-lg font-light leading-[25.2px] max-w-[806px]'>
              The most critical data and insights, highlighting the core
              performance metrics and trends across Compass SaaS benchmarks¹,
              with 38,000+ data points spanning over 12 years of quarterly data.
            </p>
          </div>
          <FiltersSection handleApply={handleApply} />
        </div>
        <div className='w-full'>
          {companyInfo.isEntered ? (
            <GrowthEnterprise onUpdate={onGrowthEnterpriseUpdate} />
          ) : (
            <div className=' px-20 pt-[15px] pb-[25px]'>
              <div className='flex flex-row justify-between compare_performance'>
                <div className='flex flex-col gap-2 items-start'>
                  <p className='text-[22px] font-light'>
                    Enter your data to overlay it with Compass SaaS benchmarks¹
                    and access more features.
                  </p>
                  <p className='text-base font-light'>
                    By entering your data you will also be able to download your
                    performance dashboard.
                  </p>
                </div>
                <ButtonWithIcon
                  id='compare_your_performance_button'
                  icon={UploadIcon}
                  label='Compare Your Performance'
                  className='bg-green-500 rounded-5xl py-1 pl-1 pr-6 flex flex-row items-center gap-4 w-[285px] h-12 cursor-pointer hover:bg-[#62c77d] transition-all duration-300'
                  onClick={onComparePerformance}
                />
              </div>
            </div>
          )}
          {!data || isLoading ? (
            <LoadingComponent errorMessage={errorMessage} />
          ) : (
            <SectionCharts
              chartDataMap={data}
              arr={arr}
              growthMotion={growthMotion}
              softwareSector={softwareSector}
              primaryCustomer={primaryCustomer}
              isCompanyDataEntered={companyInfo.isEntered}
            />
          )}
        </div>
        <div className='w-full bg-gray-300 flex flex-col items-start px-20 py-14 gap-14'>
          <div className='w-full flex flex-row justify-between gap-10 articles'>
            <HalfWidthSection
              title='Top Insights This Month'
              description='See key trends and insights that are driving growth and success for SaaS companies. Updated monthly to keep you informed. '
              icon={TopInsightsIcon}
            >
              <LinksCard
                title='Go-To-Market'
                description='Marketing Budgets & Productivity'
                link='https://www.iconiqcapital.com/growth/insights/marketing-budgets-and-productivity'
              />
              <LinksCard
                title='Product & Engineering'
                description='Engineering Leadership in an AI Era'
                link='https://www.iconiqcapital.com/growth/insights/engineering-leadership-in-an-ai-era'
              />
              <LinksCard
                title='Growth & Efficiency'
                description='Scaling SaaS from $1 to $20M'
                link='https://www.iconiqcapital.com/growth/insights/scaling-from-1-to-20-arr'
              />
              <LinksCard
                title='Growth & Efficiency'
                description='The 2024 ICONIQ Growth Resiliency Rubric'
                link='https://www.iconiqcapital.com/growth/insights/iconiq-growth-resiliency-rubric'
              />
            </HalfWidthSection>
            <HalfWidthSection
              title='Keep Exploring'
              description={
                'Find related trends and information to deepen your understanding and help you discover what’s most relevant.'
              }
              icon={KeepExploringIcon}
            >
              {renderKeepExploring()}
            </HalfWidthSection>
          </div>
          <FullWidthSection
            title='Firmographics'
            id='firmographics_section'
            description='Custom benchmarking powered by quarterly operating and financial data from global SaaS companies.'
            isAccordion
          >
            <div className='flex flex-row w-full gap-4'>
              <div className='w-[calc(50%-8px)] border border-solid border-gray-400 rounded-[4px] max-h-[350px]'>
                <div className='flex flex-row items-center w-full h-6 gap-1 pl-6 pt-6'>
                  <p className='text-xl'>Pricing Model</p>
                  <CustomTooltip
                    id='pricingModel'
                    content='The distribution of companies in our dataset based on their primary pricing model'
                    icon={HelpIcon}
                  />
                </div>
                <div className='h-full w-full'>
                  <Bar
                    data={pricingModelData}
                    options={mockedBarChartOptions}
                  />
                </div>
              </div>

              <div className='w-[calc(50%-8px)] border border-solid border-gray-400 rounded-[4px] max-h-[350px]'>
                <div className='flex flex-row items-center w-full h-6 gap-1 pl-6 pt-6'>
                  <p className='text-xl'>Sub-Sector</p>
                  <CustomTooltip
                    id='subSector'
                    content='The distribution of companies in our dataset based on their sub-sector'
                    icon={HelpIcon}
                  />
                </div>
                <div className='h-full w-full'>
                  <Bar data={subSectorData} options={mockedBarChartOptions} />
                </div>
              </div>
            </div>

            <div className='w-full mt-4'>
              <div className='w-full min-w-[200px] border border-solid border-gray-400 rounded-[4px]  h-[466px]'>
                <div className='flex flex-row items-center w-full h-6 gap-1 pl-6 pt-6'>
                  <p className='text-xl'>Geography</p>
                  <CustomTooltip
                    id='geography'
                    content='The distribution of companies in our dataset across geographies based on primary headquarters'
                    icon={HelpIcon}
                  />
                </div>
                <div className='h-full w-full'>
                  <GeoChart />
                </div>
              </div>
            </div>
          </FullWidthSection>
          <SubscribeSection />
          <div className='w-full py-6 bg-white rounded-lg flex flex-col items-center'>
            <p className='text-xl font-semibold'>We love to hear from you!</p>
            <p className='text-lg font-light text-gray-700'>
              Please send us any feedback by{' '}
              <a
                id='email_link'
                href='mailto:ICONIQGrowthInsights@iconiqcapital.com'
                className='text-blue-400 border-none text-lg font-light'
              >
                dropping a note here
              </a>
            </p>
          </div>
          <LongerDisclousure />
        </div>
        <Footer />
        <WarningModal isOpen={warningModalOpen} onClose={onCloseWarningModal} />
        <DataEntryModal
          isOpen={isOpen}
          onClose={onClose}
          mode={modalMode}
          onGrowthEnterpriseReset={onGrowthEnterpriseReset}
          filtersSoftwareSector={softwareSector}
          filtersGrowthMotion={growthMotion}
          filtersCustomerSegment={primaryCustomer}
        />
        <ToastContainer />
        <Joyride
          run={walkthroughActive}
          steps={walkthroughSteps}
          showSkipButton
          hideBackButton
          continuous
          hideCloseButton
          disableOverlayClose
          callback={handleWalkthroughCallback}
          tooltipComponent={CustomWalkthroughTooltip}
          scrollDuration={1000}
          locale={{ last: 'Finish' }}
          styles={{
            options: {
              arrowColor: '#151515',
              width: 300
            },
            overlay: {
              width:
                !(isMobile || isTablet) && warningModalConfirmed
                  ? '1280px'
                  : '100%'
            }
          }}
        />
      </main>

      {/* growth disclaimer */}
      <section className='section_companies-disclaimer'>
        <div className='padding-global'>
          <div className='container-large'>
            <div className='padding-section-xsmall is-xxs-mobile'>
              <div className='growth-disclaimer'>
                The ICONIQ Growth website does not present information relating
                to ICONIQ Capital, its investment funds, or its advisory
                business and should not be consulted for any advisory purposes.
                The ICONIQ Growth content is intended for the use of company
                founders and executives.
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
