import { Document, Font, pdf } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import { IChartData } from 'interfaces/chartData';
import { saveAs } from 'file-saver';
import { ChartPage } from './chartPage';
import { LastPage } from './lastPage';
import font from 'assets/fonts/Quadraat-Light-ICONIQ.ttf';
import { InfoPage } from './infoPage';
import DownloadIcon from 'components/SectionCharts/DownloadIcon';
// import { LoaderIcon } from './loaderIcon';
import { useAppSelector } from 'store';
import { FilterOption } from 'interfaces/components/filter';
import { getCurrentDateFormatted } from 'utils/helpers';
import LoadingModal from 'components/LoadingModal';
import CustomTooltip from 'components/Tooltip';
import ShareIcon from 'assets/icons/share.svg';
import { downloadPDF } from 'network/downloadPDF';

interface IDashboardPdf {
  chartData: IChartData[];
  onDownload: () => void;
  onDownloadDone: () => void;
  arr: FilterOption[];
  growthMotion: FilterOption[];
  softwareSector: FilterOption[];
  primaryCustomer: FilterOption[];
  loading: boolean;
  isCompanyDataEntered?: boolean;
}

export const DashboardPdf = ({
  onDownload,
  onDownloadDone,
  growthMotion,
  softwareSector,
  primaryCustomer,
  loading,
  isCompanyDataEntered
}: IDashboardPdf) => {
  const companyInfo = useAppSelector(state => state.companyInfo);

  const div2PDF = async () => {
    onDownload();
    document.body.classList.add('!overflow-hidden');

    const data = {
      uuid:
        sessionStorage.getItem('uuid') || localStorage.getItem('uuid') || '',
      url: window.location.href,
      isDownload: true
    };

    downloadPDF(data);

    setTimeout(async () => {
      let inputs: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName('div2PDF');
      let chartWidth = 0;
      for (let i = 0; i < inputs.length; i++) {
        let currWidth = Number(
          (inputs[i] as HTMLElement).style.width.replace('px', '')
        );
        if (currWidth > chartWidth) {
          chartWidth = currWidth;
        }
      }
      for (let i = 0; i < inputs.length; i++) {
        (inputs[i] as HTMLElement).style.width = chartWidth + 'px';
      }
      const canvas = await html2canvas(inputs[0] as HTMLElement, {
        scale: 2
      });
      const canvas1 = await html2canvas(inputs[1] as HTMLElement, {
        scale: 2
      });
      const canvas2 = await html2canvas(inputs[2] as HTMLElement, {
        scale: 2
      });
      const canvas3 = await html2canvas(inputs[3] as HTMLElement, {
        scale: 2
      });
      const canvas4 = await html2canvas(inputs[4] as HTMLElement, {
        scale: 2
      });
      const canvas5 = await html2canvas(inputs[5] as HTMLElement, {
        scale: 2
      });
      const canvas6 = await html2canvas(inputs[6] as HTMLElement, {
        scale: 2
      });
      const canvas7 = await html2canvas(inputs[7] as HTMLElement, {
        scale: 2
      });
      const canvas8 = await html2canvas(inputs[8] as HTMLElement, {
        scale: 2
      });
      const canvas9 = await html2canvas(inputs[9] as HTMLElement, {
        scale: 2
      });
      const canvas10 = await html2canvas(inputs[10] as HTMLElement, {
        scale: 2
      });
      Font.register({
        family: 'Iconiq Quadraat',
        fonts: [{ src: font }]
      });
      const MyDocument = (
        <Document style={{ fontFamily: 'Iconiq Quadraat' }}>
          <InfoPage companyInfo={companyInfo} />
          <ChartPage
            image={canvas.toDataURL('image/png')}
            title='YoY ARR Growth'
            pageNumber={2}
            growthMotion={growthMotion}
            primaryCustomer={primaryCustomer}
            softwareSector={softwareSector}
          />
          <ChartPage
            image={canvas1.toDataURL('image/png')}
            title='Net Dollar Retention'
            pageNumber={3}
            growthMotion={growthMotion}
            primaryCustomer={primaryCustomer}
            softwareSector={softwareSector}
          />
          <ChartPage
            image={canvas2.toDataURL('image/png')}
            title='Net Magic Number'
            pageNumber={4}
            growthMotion={growthMotion}
            primaryCustomer={primaryCustomer}
            softwareSector={softwareSector}
          />
          <ChartPage
            image={canvas3.toDataURL('image/png')}
            title='Gross Margin'
            pageNumber={5}
            growthMotion={growthMotion}
            primaryCustomer={primaryCustomer}
            softwareSector={softwareSector}
          />
          <ChartPage
            image={canvas4.toDataURL('image/png')}
            title='Rule of 40'
            pageNumber={6}
            growthMotion={growthMotion}
            primaryCustomer={primaryCustomer}
            softwareSector={softwareSector}
          />
          <ChartPage
            image={canvas5.toDataURL('image/png')}
            title='Burn Multiple'
            pageNumber={7}
            growthMotion={growthMotion}
            primaryCustomer={primaryCustomer}
            softwareSector={softwareSector}
          />
          <ChartPage
            image={canvas6.toDataURL('image/png')}
            title='ARR per FTE'
            pageNumber={8}
            growthMotion={growthMotion}
            primaryCustomer={primaryCustomer}
            softwareSector={softwareSector}
          />
          <ChartPage
            image={canvas7.toDataURL('image/png')}
            title='OpEx as a % of Revenue'
            pageNumber={9}
            growthMotion={growthMotion}
            primaryCustomer={primaryCustomer}
            softwareSector={softwareSector}
          />
          <ChartPage
            image={canvas8.toDataURL('image/png')}
            title='FCF Margin'
            pageNumber={10}
            growthMotion={growthMotion}
            primaryCustomer={primaryCustomer}
            softwareSector={softwareSector}
          />
          <ChartPage
            image={canvas9.toDataURL('image/png')}
            title='Quick Ratio'
            pageNumber={11}
            growthMotion={growthMotion}
            primaryCustomer={primaryCustomer}
            softwareSector={softwareSector}
          />
          <ChartPage
            image={canvas10.toDataURL('image/png')}
            title='Headcount Distribution'
            pageNumber={12}
            growthMotion={growthMotion}
            primaryCustomer={primaryCustomer}
            softwareSector={softwareSector}
          />
          <LastPage />
        </Document>
      );
      const pdfBlob = await pdf(MyDocument).toBlob();
      saveAs(
        pdfBlob,
        `ICONIQ Growth Compass Benchmarks ${getCurrentDateFormatted()}.pdf`
      );
      onDownloadDone();
      document.body.classList.remove('!overflow-hidden');
    }, 500);
  };

  const onShareURLClick = () => {
    navigator.clipboard.writeText(document.URL);
  };

  return (
    <div className='flex flex-row items-center gap-4'>
      {isCompanyDataEntered && (
        <div>
          <button
            className='bg-black flex items-center gap-2 px-5 h-[35px] rounded-full hover:opacity-90'
            onClick={div2PDF}
            id='download_pdf_button'
            disabled={loading}
          >
            <DownloadIcon />
            <span className='text-white text-base font-light'>
              Download Your Benchmarks
            </span>
          </button>
          <LoadingModal
            isOpen={loading}
            onClose={() => {}}
            onReset={() => {}}
          />
        </div>
      )}
      <CustomTooltip
        id='share_button'
        content='Share your benchmarks! Click this button to copy a shareable URL to your clipboard'
        icon={ShareIcon}
        onClick={onShareURLClick}
        className='w-[35px] cursor-pointer border border-solid border-gray-400 rounded-full p-[6px] shareable_url'
      />
    </div>
  );
};
